import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./views/sitemule/solutions/solutions.module').then(
        (mod) => mod.SitemuleSolutionsModule
      ),
  },
  {
    path: 'persons-contact',
    loadChildren: () =>
      import('./views/sitemule/persons-contact/contact.module').then(
        (mod) => mod.PersonsContactModule
      ),
  },
  {
    path: 'business-first-then-technology',
    loadChildren: () =>
      import('./views/sitemule/concepts/concept.module').then(
        (mod) => mod.SitemuleConceptModule
      ),
  },
  {
    path: 'sitemule-old',
    loadChildren: () =>
      import('./views/sitemule-old/homepage/sitemule-homepage.module').then(
        (mod) => mod.SitemuleHomepageModule
      ),
  },

  {
    path: 'icecap',
    loadChildren: () =>
      import('./views/icecap/homepage/icecap-homepage.module').then(
        (mod) => mod.IcecapHomepageModule
      ),
  },
  {
    path: 'icecap/contact',
    loadChildren: () =>
      import('./views/icecap/contact/icecap-contact.module').then(
        (mod) => mod.IcecapContactModule
      ),
  },

  {
    path: 'componentdesign',
    loadChildren: () =>
      import(
        './views/componentdesign/homepage/componentdesign-homepage.module'
      ).then((mod) => mod.ComponentdesignHomepageModule),
  },
  {
    path: 'componentdesign/contact',
    loadChildren: () =>
      import(
        './views/componentdesign/contact/componentdesign-contact.module'
      ).then((mod) => mod.ComponentdesignContactModule),
  },

  {
    path: 'overview',
    loadChildren: () =>
      import('./views/sitemule/overviewpage/overviewpage.module').then(
        (mod) => mod.SitemuleOverViewModule
      ),
  },
  {
    path: 'contact1',
    loadChildren: () =>
      import('./views/sitemule/contact/sitemule-contact.module').then(
        (mod) => mod.SitemuleNewContactModule
      ),
  },
  {
    path: 'logitrans-dealer-portal',
    loadChildren: () =>
      import(
        './views/sitemule/logitrans-dealer-portal/logitrans-dealer-portal.module'
      ).then((mod) => mod.LogitransDealerPortalModule),
  },
  {
    path: 'sectionoverview',
    loadChildren: () =>
      import('./views/sitemule/sectionoverview/sectionoverview.module').then(
        (mod) => mod.SectionOverviewModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./views/sitemule/blog/blog.module').then(
        (mod) => mod.SitemuleBlogModule
      ),
  },
  {
    path: 'why-ibm-is-here-to-stay',
    loadChildren: () =>
      import('./views/sitemule/neils-interview/neils-interview.module').then(
        (mod) => mod.NeilsInterviewModule
      ),
  },
  {
    path: 'download',
    loadChildren: () =>
      import('./views/sitemule/download/download.module').then(
        (mod) => mod.SitemuleDownloadModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./views/404/404.module').then((mod) => mod.NotFoundModule),
  },
];
