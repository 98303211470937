export const environment = {
  production: false,
  data: {
    contacts : [
      {
          department: 'Sales',
          phone: '+45 70203010',
          email: 'sales@sitemule.com'
      },
      {
          department: 'Support',
          phone: '+45 70203011',
          email: 'support@sitemule.com'
      },
      {
          department: 'Corporate',
          address: 'System & Method A/S \n8, Haandvaerksvinget,\n2970 Zealand'
      },
    ],
    topNavigation : [
      {
        text: 'About',
        link: '/business-first-then-technology',
        special: false,
        textOver: 'business first then technology',
      },
      {
        text: 'Contact',
        link: '/#lest-have-a-talk',
        special: false,
        textOver: 'contacts',
      },
    ],
    allPersons : [
      {
        name: 'Martin Hecht Olsen',
        position: 'CEO',
        image: '../../../../assets/sitemule-members/martin-hecht-olsen.jpg',
        phone: '+45 40 11 77 11',
        email: 'mho@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Niels Liisberg',
        position: 'Chief Innovation Officer',
        image: '../../../../assets/sitemule-members/niels-liisberg.jpg',
        phone: '+45 31 15 88 61',
        email: 'nli@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Jens Churchill',
        position: 'CTO',
        image: '../../../../assets/sitemule-members/jens-churchill.jpg',
        phone: '+45 30 44 80 04',
        email: 'jmc@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Sandesh Shrestha',
        position: 'Principal, Frontend',
        image: '../../../../assets/sitemule-members/sandesh-shrestha.jpg',
        phone: '+45 27 50 64 78',
        email: 'san@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Kenneth Riber Andersen',
        position: 'Principal, UX UI',
        image: '../../../../assets/sitemule-members/kenneth-riber-andersen.jpg',
        phone: '+45 61 47 26 26',
        email: 'kra@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Grith Skovboelle',
        position: 'Support Lead',
        image: '../../../../assets/sitemule-members/grith-skovboelle.jpg',
        phone: '+45 51 27 44 43',
        email: 'gsk@sitemule.com',
        showInSmallSize: true,
      },
      {
        name: 'Bent Witte Rasmussen',
        position: 'Developer',
        image: '../../../assets/sitemule-members/bent.jpg',
        phone: '+45 20 87 55 26',
        email: 'bwr@sitemule.com',
      },
      {
        name: 'Claus Tümmler',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 25 23 22 40',
        email: 'ctu@sitemule.com',
      },
      {
        name: 'Glenn Jarzomkowski',
        position: 'Developer',
        image: '../../../assets/sitemule-members/glenn.jpg',
        phone: '+45 26 73 45 99',
        email: 'gja@sitemule.com',
      },
      {
        name: 'Jeppe K. Bundgaard',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 50 70 35 77',
        email: 'jkb@sitemule.com',
      },
      {
        name: 'Jørn Holm',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 31 55 04 10',
        email: 'jho@sitemule.com',
      },
      {
        name: 'Claus Rytter Larsen',
        position: 'Developer',
        image: '../../../assets/sitemule-members/claus.jpg',
        phone: '+45 20 27 19 99',
        email: 'crl@sitemule.com',
      },
      {
        name: 'Peter Donabed Nøbbe',
        position: 'Developer',
        image: '../../../assets/sitemule-members/peter.jpg',
        phone: '+45 60 24 59 52',
        email: 'pno@sitemule.com',
      },
      {
        name: 'Emil Fridthjof',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 28 73 90 34',
        email: 'erf@sitemule.com',
      },
      {
        name: 'Morten Pedersen',
        position: 'Developer',
        image: '../../../assets/sitemule-members/morten.jpg',
        phone: '+45 28 49 15 20',
        email: 'mop@sitemule.com',
      },
      {
        name: 'Bhuwan Poudel',
        position: 'Developer',
        image: '../../../assets/sitemule-members/default-image.png',
        phone: '+45 81 91 68 93',
        email: 'bks@sitemule.com',
      },
      {
        name: 'Henning Ørnsø',
        position: 'Hosting Manager',
        image: '../../../assets/sitemule-members/henning.jpg',
        phone: '+45 40 88 87 24',
        email: 'hrn@sitemule.com',
      },
    ],
    customers : [
      {
          logo: '../../../../assets/customers-logo/yx.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/dfd.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/logitrans.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/coownership.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/nomeco.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/dancenter.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/berry-superfos.png',
          name: 'berry-superfos',
          description: '',
      },
      {
          logo: '../../../../assets/customers-logo/ftz.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/santander.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/dhl.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/hch-gruppen.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/al-finans.png',
          name: 'al-finans',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/tjl.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/scania.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/politietssprogforbund.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/folkelarsen.png',
          name: 'coownership',
          description: '',
      },
      {
          logo: '../../../../assets/customers-logo/volvo.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/midfactoring.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/expedit.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/stark.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/marius-pedersen.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/molslinjen.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/htbendix.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
      {
          logo: '../../../../assets/customers-logo/rg.png',
          name: 'coownership',
          description: '',
          link: '/'
      },
    ],
  }
};
